class Logger {
  constructor(color, identifier, enableOnProduction) {
    this.color = color;
    this.identifier = identifier || false;
    this.flags = {
      ERROR: {
        label: '💥 Error',
        hex: '#d50000',
      },
      WARNING: {
        label: '⚠️ Warning',
        hex: '#ffc107',
      },
      INFO: {
        label: '💬 Info',
        hex: '#00BCD4',
      },
      PERFORMANCE: {
        label: '📊 Performance',
        hex: '#673ab7',
      },
      SUCCESS: {
        label: '✔️ Success',
        hex: '#4caf50',
      },
      STANDARD: {
        label: '',
        hex: '#4caf50',
      },
    };
    this.initTime = Date.now();
    this.enableOnProduction = enableOnProduction;
    this.performanceMonitoring = true;
  }

  prefixCssString(flag, options) {
    let css = 'margin-bottom: 0.25em; ';
    css += `color: ${options.color || this.color}; `;
    css += 'font-size: 1.3em; ';
    return css;
  }

  bodyCssString(flag) {
    let css = 'margin-bottom: 0.25em; ';
    if (flag) {
      css += `border-color: ${flag.hex}; `;
    }
    css += 'border-radius: 0 2px 2px 0; ';
    css += 'border-left-width: 1.5em; ';
    css += 'margin-left: 1em; ';
    return css;
  }

  suffixCssString() {
    let css = 'font-style: italic; font-size: 0.8em; border: 1px solid black; ';
    css += `border-bottom-color: ${this.color}; border-width: 0 0 1px; `;
    return css;
  }

  flagLevelIndicatorCssString(flag) {
    const css = `color: ${flag.hex}; margin-left: 0.5rem; font-size: 1em; `;
    return css;
  }

  subHeadingCssString(flag, options) {
    return `
      color: ${options.subheadingColor || this.color};
      margin-left: 1em;
    `;
  }

  loggingEnabled() {
    if (
      this.enableOnProduction ||
      (import.meta.env.DEV && !window.disableLogger)
    ) {
      return true;
    }
    return false;
  }

  log(message, flag, contents = [], options = {}) {
    if (!Array.isArray(contents)) {
      contents = [contents];
    }

    if (!this.loggingEnabled()) {
      return;
    }
    if (!flag) {
      flag = this.flags.STANDARD;
    }
    const flagMessage = 'expand for more context';
    // eslint-disable-next-line
    if (typeof console.groupCollapsed === 'function') {
      // eslint-disable-next-line
      console.groupCollapsed(
        `%c${this.identifier}%c${flag.label}%c${
          options.subheading || ''
        }%c\n> ${message}\n%c${flagMessage}`,
        this.prefixCssString(flag, options),
        this.flagLevelIndicatorCssString(flag),
        this.subHeadingCssString(flag, options),
        this.bodyCssString(flag),
        this.suffixCssString(flag)
      );

      contents.forEach(content => {
        if (!Array.isArray(content)) {
          content = [content];
        }
        // eslint-disable-next-line
        console.log(...content);
      });
      // eslint-disable-next-line
      console.trace();
      // eslint-disable-next-line
      console.groupEnd();
      if (typeof message === 'object') {
        // eslint-disable-next-line
        console.log(message);
      }
    } else {
      // eslint-disable-next-line
      console.log(
        `%c${this.identifier}:%c${message}`,
        this.prefixCssString(flag),
        this.bodyCssString(flag)
      );
    }
  }

  table(data, cols) {
    if (!this.loggingEnabled()) {
      return;
    }
    // eslint-disable-next-line
    console.table(data, cols);
  }

  mark(message) {
    if (!this.performanceMonitoring) {
      return;
    }
    if (!this.observer) {
      this.createObserver();
      performance.mark('loggerPerformanceMarkStart');
    }
    performance.mark(message);
  }

  measure(message) {
    if (!this.performanceMonitoring) {
      return false;
    }
    try {
      performance.measure(message, 'loggerPerformanceMarkStart');
    } catch (e) {
      return e;
    }
    return true;
  }

  beginFrame() {
    if (!this.performanceMonitoring) {
      return;
    }
    this.frameTime = performance.now();
  }

  createObserver() {
    if (!this.performanceMonitoring) {
      return;
    }
    this.observer = new PerformanceObserver(items => {
      const itemList = items.getEntries();
      const startTime = itemList[0].startTime;
      const tableData = {};
      for (let i = 0, l = itemList.length; i < l; ++i) {
        /* tableData.push({
          name: itemList[i].name,
          delta: itemList[i].startTime - startTime,
        }); */
        tableData[itemList[i].name] = itemList[i].startTime - startTime;
      }
      this.log('FRAME', this.flags.PERFORMANCE);
      this.table(tableData, ['name', 'delta']);
      performance.clearMarks();
    });
    this.observer.observe({entryTypes: ['mark', 'measure']});
  }
}

export default Logger;
